import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { Button, message } from "antd";
import { syncweworker } from "@/framework/api/godiva";
export default function CmsAuthLogin() {
    useEffect(()=>{
        const query = queryString.parse(window.location.search);
        const {code} = query;
        if(code){
            syncweworkerfn(code)
        }
    },[])

    const syncweworkerfn = async (code:any)=>{
        const res = await syncweworker(code)
        if(res){
            message.success('更新成功！')
        }else{
            message.error('更新失败,请联系管理员！')
        }
    }

    return <div>
                <div className=" flex items-center justify-center h-[calc(100vh-20px)]">
                   <div className="p-[20px]">
                        <div className=" flex justify-center mb-[50px]">
                            <Button type="primary" onClick={()=>{
                                const redirect_uri = 'https://smartretail.dev.d2cgo.com/cms_login?rc_access_ticket=1&token=1'
                                const agentid = '1000002'
                                const appid = 'wwcb089ea3c9be0362'
                                window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_privateinfo&state=STATE&agentid=${agentid}#wechat_redirect`
                            }}>更新基本信息</Button>
                        </div>

                        <div className=" text-12 text-gray-500">当前更新用作当前企业微信中的账号信息与小程序同步，如您修改了头像和昵称请确保点击更新！</div>
                   </div>
                </div>
          </div>
}   