// 引入icon样式文件
// import "../assets/css/iconfont/iconfont"
import partnerSvg from '@/assets/images/partner.svg'
import { MenuObject } from './cms-menus'
import home from '../../src/assets/icon/icons8-home (2).json'
import shipping from '../../src/assets/icon/icons8-shipment.json'
import order from '../../src/assets/icon/icons8-order.json'
import subscription from '../../src/assets/icon/icons8-subscription.json'
import product from '../../src/assets/icon/icons8-product.json'
import consumer from '../../src/assets/icon/icons8-consumer.json'
import marketing from '../../src/assets/icon/icons8-marketing.json'
import ai from '../../src/assets/icon/icons8-ai.json'
import partner from '../../src/assets/icon/icons8-partner.json'
import importandexport from '../../src/assets/icon/icons8-import&export.json'
import invoice from '../../src/assets/icon/icons8-invoice.json'
import channel from '../../src/assets/icon/icons8-channel.json'
import React from 'react'
export const menus: MenuObject[] = [
  // {
  //   name: 'Dashboard',
  //   value: 'dashboard',
  //   url: '/dashboard',
  //   activekey: 'dashboard',
  //   key: 'salesExperienceDashboard',
  //   icon: <span className="icon iconfont text-xl icon-a-bianzu33" />,
  //   lottieIcon: home,
  //   langKey: 'menu.dashboard',
  // },
  {
    name: 'Product',
    value: 'product',
    url: '',
    key: 'product',
    activekey: 'product',
    icon: <span className="icon iconfont icon-a-bianzu12" />,
    lottieIcon: product,
    langKey: 'menu.product',
    children: [
      {
        name: 'My Products',
        value: 'my_products',
        activekey: 'product-list',
        url: '/product/product-list',
        key: 'product.productList',
        langKey: 'menu.my_products',
      },
      {
        name: 'Gift Card Management',
        value: 'gift_card_management',
        activekey: 'gift-card-management',
        url: '/product/gift-card-management',
        key: 'product.giftCardManagement',
        langKey: 'Gift Card Management',
      },
      // {
      //   name: 'Add New Product',
      //   value: 'add_new_product',
      //   activekey: 'product-add',
      //   url: '/product/product-list/product-add',
      //   key: 'product.addNewProduct',
      //   langKey: 'menu.add_new_product',
      // },
      // {
      //   name: 'Search',
      //   value: 'product_search',
      //   url: '/product/product-search',
      //   activekey: 'product-search',
      //   key: 'product.search',
      //   langKey: 'menu.search',
      // },
      // {
      //   name: 'product.categrey',
      //   value: 'product_categrey',
      //   url: '/product/product-categrey',
      //   activekey: 'product-categrey',
      //   key: 'product.categrey',
      //   langKey: 'menu.product.categrey',
      // },
      // {
      //   name: 'Descriptions',
      //   value: 'description',
      //   activekey: 'description',
      //   url: '/product/description',
      //   key: 'product.descriptions',
      //   langKey: 'menu.description',
      // },
      // {
      //   name: 'Attributes',
      //   value: 'attributes',
      //   activekey: 'attributes',
      //   url: '/product/attributes',
      //   key: 'product.attributes',
      //   langKey: 'menu.attributes',
      // },
      // {
      //   name: 'Filters & Sort',
      //   value: 'filter',
      //   url: '/product/filter',
      //   activekey: 'filter',
      //   key: 'product.filtersAndSort',
      //   langKey: 'menu.filter',
      // },
      // {
      //   name: 'Product Setting',
      //   value: 'product-setting',
      //   url: '/product/product-setting',
      //   activekey: 'productSetting',
      //   key: 'product.productSetting',
      //   langKey: 'menu.productSetting',
      // },
    ],
  },
  {
    name: 'Order',
    value: 'order',
    url: '',
    key: 'order',
    activekey: 'order',
    lottieIcon: order,
    icon: <span className="icon iconfont icon-a-bianzu11" />,
    langKey: 'menu.order',
    children: [
      {
        name: 'My Orders',
        value: 'my_orders',
        activekey: 'order-list',
        url: '/order/order-list',
        // url: "/",
        key: 'order.myOrders',
        langKey: 'menu.my_orders',
      },
      // {
      //   name: 'Valet Order',
      //   value: 'valetorder',
      //   activekey: 'valet-order',
      //   url: '/order/valet-order',
      //   // url: "/",
      //   key: 'order.myOrders',
      //   langKey: 'menu.valetorder',
      // },
      // {
      //   name: 'Return Order',
      //   value: 'return_order',
      //   activekey: 'return-order',
      //   url: '/order/order-return-list',
      //   key: 'order.returnOrder',
      //   langKey: 'menu.Return Order',

      // },
      // 关闭入口，放到order setting里
      // {
      //   name: 'Order flow',
      //   value: 'order_flow',
      //   activekey: 'order-flow',
      //   url: '/order/order-flow',
      //   key: 'order.flow',
      //   langKey: 'menu.order_flow',
      // },
      // {
      //   name: 'Order Setting',
      //   value: 'order_setting',
      //   activekey: 'order-setting',
      //   url: '/order/order-setting',
      //   key: 'order.orderSetting',
      //   langKey: 'menu.order_setting',
      // }
      // {
      //   name: 'Order Eligible For Return',
      //   value: 'order_eligible_for_return',
      //   activekey: 'order-eligible-for-return',
      //   url: '/order/order-return-apply-list',
      //   key: 'order.orderEligibleForReturn',
      //   langKey: 'menu.Order Eligible For Return',
      // }
      // ,

    ],
  },
 

  {
    name: 'Pet Owner',
    value: 'petOwner',
    url: '',
    key: 'consumer',
    activekey: 'consumer',
    icon: <span className="icon iconfont icon-a-bianzu12-1" />,
    lottieIcon: consumer,
    langKey: 'menu.pet_owner',
    children: [
      {
        name: 'My Pet Owner',
        value: 'my_pet_owner',
        url: '/consumer/consumer-list',
        activekey: 'consumer-list',
        key: 'consumer.consumerList',
        langKey: 'menu.my_pet_owner',
      },
      // {
      //   name: 'Tagging Setting',
      //   value: 'tagging_setting',
      //   activekey: 'tag-list',
      //   url: '/tags/tag-list',
      //   key: 'consumer.taggingSetting',
      //   langKey: 'menu.tagging_setting',
      // },
      // {
      //   name: 'Loyalty',
      //   value: 'loyalty',
      //   url: '',
      //   key: 'consumer.loyalty',
      //   activekey: 'loyalty',
      //   langKey: 'menu.loyalty',
      //   children: [
      //     {
      //       name: 'Loyalty Program',
      //       value: 'loyalty_program',
      //       activekey: 'loyalty-programs',
      //       url: '/consumer/loyalty/loyalty-programs',
      //       key: '',
      //       langKey: 'menu.loyalty_program',
      //     },
      //     {
      //       name: 'Points',
      //       value: 'points',
      //       url: '/consumer/loyalty/points',
      //       activekey: 'points',
      //       key: '',
      //       langKey: 'menu.points',
      //     },
      //     {
      //       name: 'Referrals',
      //       value: 'referrals',
      //       url: '/consumer/loyalty/referrals',
      //       activekey: 'referrals',
      //       key: '',
      //       langKey: 'menu.referrals',
      //     },
      //     {
      //       name: 'Reward History',
      //       value: 'rewardHistory',
      //       activekey: 'reward-history',
      //       url: '/consumer/loyalty/reward-history',
      //       key: '',
      //       langKey: 'menu.rewardHistory',
      //     },
      //     {
      //       name: 'Reward Settings',
      //       value: 'rewardSettings',
      //       url: '/consumer/loyalty/reward-settings-list',
      //       activekey: 'reward-settings-list',
      //       langKey: 'menu.rewardSettings',
      //       key: ''
      //     }]
      // },

    ],
  },
  // {
  //   name: 'Marketing Automation',
  //   value: 'marketing_centres',
  //   url: '',
  //   key: 'marketing',
  //   activekey: 'marketingCenter',
  //   icon: <span className="icon iconfont icon-a-bianzu13" />,
  //   lottieIcon: marketing,
  //   langKey: 'menu.marketing',
  //   children: [
  //     {
  //       name: 'Marketing Experience',
  //       value: 'MarketingCenters',
  //       url: '/marketingCenter/marketingCenter-list',
  //       activekey: 'marketingCenter-list',
  //       key: 'marketing.marketingCenter',
  //       langKey: 'menu.marketing_center',
  //     },
  //     {
  //       name: 'Intelligent Recommendation',
  //       value: 'IntelligentRecommendation',
  //       activekey: 'intelligentRecommendation-list',
  //       url: '/marketingCenter/intelligentRecommendation-list',
  //       key: 'marketing.intelligentRecommendation',
  //       langKey: 'menu.intelligent_recommendation',
  //     },
  //   ],
  // },
  {
    name: 'Exports',
    url: '/export-report',
    key: 'salesChannel.exportReport',
    icon: <span className='iconfont icon icon-tiaobojilu  text-[17px]'></span>,
    activekey: 'export-report',
    langKey: 'menu.exports',
    value: 'export-report',
    lottieIcon: importandexport,
  },
]

// export const initActive = (pathname: string) => {
//   let selectedKeys: string[] = []
//   menus.forEach((menu) => {
//     menu.children.forEach((subMenu) => {
//       if (subMenu.url === pathname) {
//         selectedKeys = [menu.key, subMenu.key]
//       }
//     })
//   })
//   return selectedKeys
// }
