import { useState, useContext } from "react";
import { User } from "@/framework/types/common";
import intl from 'react-intl-universal';
import logo from "../../../assets/images/fresh_logo.jpg"
import { ConfigProvider, Menu, message, Popover, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router";
import { useAtom } from 'jotai';
import { langAtom } from '@/store/lang.store';
import { TLangKey } from '@/locale';
import { TabContext } from '../../../contexts/TabContext'
import "./index.less"

// import integrationImg from '@/assets/images/cms-menu/integration.svg';
import menuImg from '@/assets/images/cms-menu/menu.svg';
import shopHome from '@/assets/images/cms-menu/shop-home.svg';
import salesCenterIcon from '@/assets/images/cms-menu/salesCenter.svg'
// import contentCenterIcon from '@/assets/images/cms-menu/contentCenter.svg'
// import consumerCenterIcon from '@/assets/images/cms-menu/consumerCenter.svg'
// import maIcon from '@/assets/images/cms-menu/ma.svg'
// import fulfilmentIcon from '@/assets/images/cms-menu/fulfillment.svg'
// import reportingIcon from '@/assets/images/cms-menu/reporting.svg'
// import settingIcon from '@/assets/images/cms-menu/setting1.svg'
// import apiCenterIcon from '@/assets/images/cms-menu/apiCenter.svg'
// import pimIcon from '@/assets/images/cms-menu/pim.svg'
import salesCenterIcon_Active from '@/assets/images/cms-menu/salesCenter_active.svg'
// import contentCenterIcon_Active from '@/assets/images/cms-menu/contentCenter_active.svg'
// import consumerCenterIcon_Active from '@/assets/images/cms-menu/consumerCenter_active.svg'
// import maIcon_Active from '@/assets/images/cms-menu/ma_active.svg'
// import fulfilmentIcon_Active from '@/assets/images/cms-menu/fulfillment_active.svg'
// import reportingIcon_Active from '@/assets/images/cms-menu/reporting_active.svg'
// import settingIcon_Active from '@/assets/images/cms-menu/setting_active.svg'
// import apiCenterIcon_Active from '@/assets/images/cms-menu/apiCenter_active.svg'
import languageIcon from '@/assets/images/cms-menu/language.svg'
import shopIcon from '@/assets/images/cms-menu/shop.svg'
import logoutIcon from '@/assets/images/cms-menu/logout.svg'
import checkIcon from '@/assets/images/country/check.svg'
import helpIcon from '@/assets/images/cms-menu/icon-help.svg'
import { languages, TLangItem } from '@/common/components/Header/constants/languages'
import Cookies from "js-cookie";
import { getDataFormSeamCookie, getTickte, loginOut, removeToken, removeToSeamCookie, setToSemeCookie, uuid } from "@/utils/utils";
import { getTokenByTicktAndCliant } from "@/framework/api/auth";
import { getcliantId } from "@/common/components/authloading";
type TModuleItem = {
  key: string,
  desc: string,
  icon: any,
  activeIcon: any,
  url: string,
  value?: number,
  active?: boolean,
  blank?: boolean
  width?: number,
  height?: number
}

enum ModuleUrl {
  SALES_CENTER = `https://dtccenter.d2cgo.com/dashboard`,
  CONTENT_CENTER = 'https://content.d2cgo.com/cms-quick-start',
  CONSUMER_CENTER = 'https://cdp.d2cgo.com',
  MA = 'https://ma.d2cgo.com',
  REPORTING = 'https://reporting.d2cgo.com',
  SETTING = 'https://setting.d2cgo.com/dashboard',
  API_CENTER = 'https://api.d2cgo.com'
}

export const modules = [
  {
    key: 'smartretail',
    desc: 'smartretail app',
    icon: salesCenterIcon,
    activeIcon: salesCenterIcon_Active,
    url: `${process.env.REACT_APP_SELLER_CENTER_DOMAIN}/authloading`,
    value: 0,
    active: true,
    appId: '3038ee8e-98aa-4fa0-aa7e-3df07ab5488e',
    openUrl: `${process.env.REACT_APP_OPEN_DOMAIN}/v11/detail/SellerCenter`,
  },
  // {
  //   key: 'salesCenter',
  //   desc: 'Sales Experience',
  //   icon: salesCenterIcon,
  //   activeIcon: salesCenterIcon_Active,
  //   url: `${process.env.REACT_APP_SELLER_CENTER_DOMAIN}/authloading`,
  //   value: 0,
  //   active: true,
  //   appId: '09f60950-6c2f-4283-a67f-2719ef5cf32e',
  //   openUrl: `${process.env.REACT_APP_OPEN_DOMAIN}/v11/detail/SellerCenter`,
  // },
  // {
  //   key: 'contentCenter',
  //   desc: 'Content Experience',
  //   icon: contentCenterIcon,
  //   activeIcon: contentCenterIcon_Active,
  //   url: `${process.env.REACT_APP_CONTENT_DOMAIN}/authloading`,
  //   value: 1,
  //   active: true,
  //   appId: '697b1ff1-58b1-47bf-bf79-90030ef1f39e',
  //   openUrl: `${process.env.REACT_APP_OPEN_DOMAIN}/v11/detail/doc_CE`,
  // },
  // {
  //   key: 'consumerCenter',
  //   desc: 'Consumer Experience',
  //   icon: consumerCenterIcon,
  //   activeIcon: consumerCenterIcon_Active,
  //   url: `${process.env.REACT_APP_CONSUMER_DOMAIN}/authloading`, //cdp.d2cgo.com
  //   value: 3,
  //   active: true,
  //   appId: '89692126-1a6c-40cb-8048-280409da1f1c'
  // },
  // {
  //   key: 'ma',
  //   desc: 'MA Experience',
  //   icon: maIcon,
  //   activeIcon: maIcon_Active,
  //   active: true,
  //   value: 5,
  //   url: `${process.env.REACT_APP_MA_DOMAIN}/authloading`,// ma.com
  //   appId: '837fa681-2d7d-4c21-9171-cf7ac168850a'
  // },
  // {
  //   key: 'order_fulfilment',
  //   desc: 'Fulfillment Experience',
  //   icon: fulfilmentIcon,
  //   activeIcon: fulfilmentIcon_Active,
  //   url: `${process.env.REACT_APP_OMS_DIMAIN}/authloading`, // ma.com
  //   appId: '1efd36bf-a026-4715-a5b4-bd7fa4d265f9',
  //   active: true,
  //   value: 0,
  // },
  // {
  //   key: 'setting',
  //   desc: 'Global Setting',
  //   icon: settingIcon,
  //   activeIcon: settingIcon_Active,
  //   url: `${process.env.REACT_APP_SETTING_DOMAIN}/authloading`, // setting.d2cgo.com
  //   active: true,
  //   blank: false,
  //   value: 2,
  //   appId: '808fac56-bd6d-11ed-8ae8-00163e02a658',
  //   openUrl: `${process.env.REACT_APP_OPEN_DOMAIN}/v11/detail/doc_Setting`,
  // },
  // {
  //   key: 'reporting',
  //   desc: 'Smart Operation',
  //   icon: reportingIcon,
  //   width: 26.76,
  //   height: 35.56,
  //   activeIcon: reportingIcon_Active,
  //   active: true,
  //   blank: false,
  //   value: 4,
  //   url: `${process.env.REACT_APP_REPORTING_DOMAIN}/authloading`,
  //   appId: '6445e261-45e8-4e33-8f8c-2cf6b0c783a5'
  // },
  // {
  //   key: 'integration',
  //   desc: 'Integration Portal',
  //   icon: integrationImg,
  //   activeIcon: integrationImg,
  //   url: `${process.env.REACT_APP_INTEGRATION_DOMAIN}/authloading`, // setting.d2cgo.com
  //   active: true,
  //   value: 20,
  //   blank: false,
  //   appId: '559dd36f-4177-11ee-8ae8-00163e02a658'
  // },
  // {
  //   key: 'apiCenter',
  //   desc: 'Open Platform',
  //   icon: apiCenterIcon,
  //   activeIcon: apiCenterIcon_Active,
  //   url: `${process.env.REACT_APP_OPEN_DOMAIN}/authloading`, // setting.d2cgo.com
  //   active: true,
  //   blank: true,
  //   value: 10,
  //   appId: '9c1d8cc0-c975-4fb5-991f-a7c652bee891'
  // },
  // {
  //   key: 'pim',
  //   desc: 'POS',
  //   icon: pimIcon,
  //   activeIcon: pimIcon_Active,
  //   url: '/comingSoon' // setting.d2cgo.com
  // }
]

// 跳转其他系统携带token处理
export const toOtherSysUrl = async (i: number, pathname = '/', blank?: boolean) => {
  const item = modules[i]
  // if (process.env.NODE_ENV === "production") {  // 本地开发在当前域名跳转，线上在不同域名跳转
  // 为了处理退出登录的问题，在跳转到其他应用的时候，需要先拿到当前用户的token，再跳转到其他域名，保持用户统一。
  try {
    const token = await getTokenByTicktAndCliant(getTickte(), item.appId)
    const hrefUrl = `${item.url}?cliantId=${item.appId}&token=${token.data.result.accessToken.replace(/\s/g, "_")}&rc_access_ticket=${getTickte()}&redirect=${pathname}`;

    if (blank) {
      window.open(hrefUrl)
    } else {
      window.location.href = hrefUrl
    }
  } catch (error) {
    window.location.href = `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
  }
  // }
}

const MenuComp = (navigate: Function, userInfo: any) => {

  const [checked, setChecked] = useState(true)
  const location = useLocation()
  const [activeLang, setActiveLang] = useState(getDataFormSeamCookie('locale') ?? 'en_US')
  const [lang, setLang] = useAtom(langAtom)
  const handleThemeChange = (checked: boolean) => {
    if (checked) {
      setToSemeCookie('theme', '#ee4d2d')
      ConfigProvider.config({
        theme: { primaryColor: '#ee4d2d', },
      });
    } else {
      setToSemeCookie('theme', 'blue')
      ConfigProvider.config({
        theme: { primaryColor: 'blue', },
      });
    }
  }

  return (
    <div className="pt-5 top-header-dropdown">
      <div className="w-full flex flex-col justify-center items-center mb-2">
        {/* <img alt="" className="inline-block align-middle" src={userIcon} /> */}
        <div className="rounded-full w-9 h-9 flex justify-center items-center text-lg text-white" style={{ background: '#397DFF' }}>{userInfo?.username?.substr(0, 1)}</div>
        <span className="align-middle mt-2 ">{userInfo?.username}</span>
        <span className="mt-2" style={{ color: '#555555' }}>{userInfo?.email || userInfo?.phone}</span>
      </div>

      <Menu style={{ border: 'unset' }} className="header-menu-flex" mode="vertical">
        {/* <Menu.Item key={"Manage Account"}>
          Manage Account
        </Menu.Item> */}
        {/* <Menu.SubMenu style={{ paddingLeft: '0', borderTop: '1px solid var(--divider)' }} key={"Language"} title={<span className="flex items-center "><img alt="" width={16} className="inline-block align-middle mr-2.5" src={languageIcon} />
          <span>{intl.get('header.Switch Language')}</span> <span className="px-1.5">-</span> {languages.filter(v => v.key === activeLang)?.map(item => <span className={`flex items-center justify-between `} >
            <span className="flex items-center text-xs">
              <img alt="" width={18} className="inline-block align-middle mr-2.5" src={item.img} />{intl.get(item.langKey)}
            </span>
          </span>)}</span>} >
          {languages.map((item: TLangItem, index: number) => {
            return (
              <Menu.Item key={item.key} onClick={() => { setActiveLang(item.key); setToSemeCookie("locale", item.key); setLang(item.key as TLangKey); navigate(0) }} style={{ height: 'auto', padding: '8px 20px', margin: '0', background: 'transparent', paddingTop: index === 0 ? '16px' : '8px', paddingBottom: index === languages.length - 1 ? '16px' : '8px' }} >
                <span className={`flex items-center justify-between `} style={{ width: '160px' }}>
                  <span className="flex items-center text-xs">
                    <img alt="" width={18} className="inline-block align-middle mr-2.5" src={item.img} />{intl.get(item.langKey)}
                  </span>
                  {activeLang === item.key ? <img alt="" width={16} className="inline-block align-middle mr-2.5" src={checkIcon} /> : <span></span>}
                </span>
              </Menu.Item>
            )
          })}
        </Menu.SubMenu> */}
        <Menu.SubMenu key={"store"} popupClassName="hidden" title={<span className="flex items-center " onClick={() => {
          // navigate('/login/store')
          navigate('/store/select', { state: { canBack: true } })
        }}><img alt="" width={16} className="inline-block align-middle mr-2.5" src={shopIcon} />{getDataFormSeamCookie('rc_sc_login_brand')}<span className="px-1.5">-</span>{getDataFormSeamCookie('rc_sc_login_store')}</span>} >
        </Menu.SubMenu>
        {/* <Menu.Item
          style={{ borderBottom: '1px solid var(--divider)' }}
          key={"Theme"}
        >
          {intl.get('header.Switch Theme')}<Switch className="ml-5" checkedChildren={intl.get('header.Orange')} unCheckedChildren={intl.get('header.Blue')} defaultChecked={getDataFormSeamCookie('theme') !== intl.get('header.Blue')} onChange={(checked) => handleThemeChange(checked)} />
        </Menu.Item> */}
        <Menu.Item
          key={"Logout"}
          onClick={() => {
            try {
              loginOut({
                token: Cookies.get('rc_access_token'),
                signOutUrl: `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`,
                clientId: getcliantId(),
                os: navigator.platform,
                ip: window.location.host,
                deviceName: navigator.appName,
                deviceId: uuid(),
                state: 'zero'
              })
              removeToken()
              localStorage.removeItem('rc-userInfo')
              localStorage.removeItem("rc_access_cliant")
              removeToSeamCookie('locale')
              if (process.env.NODE_ENV == 'production') {
                window.location.href = `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
              } else {
                navigate('/login')
              }
            } catch (error) {
              removeToken()
              if (process.env.NODE_ENV == 'production') {
                window.location.href = `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
              } else {
                navigate('/login')
              }
            }
          }}>
          <span className="flex items-center">
            <img alt="" width={16} className="inline-block align-middle mr-2.5" src={logoutIcon} />{intl.get("header.logout")}
          </span>

        </Menu.Item>
      </Menu>
    </div>
  )
}

const ModuleMenuComp = (navigate: Function, handleClick: Function) => {
  return (
    <div className="grid grid-cols-3  ">
      {modules.map((item) => {
        if (((!localStorage.getItem('rc_access_cliant') || localStorage.getItem('rc_access_cliant')?.indexOf(item.appId) == -1)) && process.env.NODE_ENV == 'production') {
          return ''
        } else {
          return (
            // <a href={item.url} className="text-gray-600">
            <div key={item.desc} className="flex flex-col justify-center items-center cursor-pointer hover:bg-gray-50 p-3" style={{ color: '#333333' }} onClick={() => handleClick(item)}>
              <div className="h-[30px] flex items-center">
                <img alt="" className="inline-block align-middle text-gray-300" height={30} src={item.active ? item.activeIcon : item.icon} />

              </div>
              {/* <Icon component={() => item.icon} /> */}
              <span className="align-middle mt-2">{item.desc}</span>
            </div>
            // </a>
          )
        }
      })}
    </div>
  )
}

const LangOpts = (changeLang: Function) => {
  return (
    <Menu>
      {languages.map((item: TLangItem, idx: number) => (
        <Menu.Item
          key={idx}
          onClick={() => {
            changeLang(item.key)
          }}
        >
          {item.desc}
        </Menu.Item>
      ))}
    </Menu>
  )
}


const Header = ({ userInfo }: { userInfo: User | null }) => {
  const [lang, setLang] = useAtom(langAtom)

  const { activeTab, sendActiveTab } = useContext(TabContext)
  const navigate = useNavigate();
  const changeLang = (key: TLangKey) => {
    setToSemeCookie("locale", key);
    setLang(key);
    // window.location.reload();
  }

  const { pathname } = useLocation();
  const handleShopHome = () => {
    // const brand =  getDataFormSeamCookiegin_brand')
    const store = getDataFormSeamCookie('rc_sc_login_store')?.toLowerCase()
    const storeArr = store?.split(' ') || []
    const country = storeArr[1]
    let domain: any = storeArr[0] || ''
    let path = ''
    if (domain) {
      switch (domain) {
        case 'malts':
          domain = process.env.REACT_APP_SHOP_DOMAIN_MALTS
          break
        default:
          domain = ''
      }
    }
    if (country) {
      switch (country) {
        case 'de':
          path = '/de-de'
          break
        case 'nl':
          path = '/nl-nl'
          break
        case 'gb':
          path = '/en-gb'
          break
        case 'row':
          path = '/en-row'
          break
        case 'us':
          path = 'en-us'
          break
        case 'au':
          path = '/en-au'
          break
        case 'be':
          path = '/en-be'
          break
        default:
          path = ''
      }
    }
    if (domain) {
      window.open(domain + path)
    }


  }
  const handleClick = async (item: any) => {
    // @ts-ignore
    if (localStorage.getItem('rc_access_cliant')?.indexOf(item.appId) < 0) return message.error(intl.get('nopermission'))
    if (item.value !== undefined) {
      setToSemeCookie('rc_sc_login_module', item.desc)

      setToSemeCookie('rc_cliant', item.appId)
      if (item.value === 0) {
        setToSemeCookie('activeTab', '0');
        if (process.env.NODE_ENV === 'development') {
          sendActiveTab(item.value);
          navigate('/dashboard');
          //window.location.href = `http://seller.zys.com:3000/authloading?cliantId=${item.appId}`
        }
      } else if (item.value === 1) {
        setToSemeCookie('activeTab', '1');
        if (process.env.NODE_ENV === 'development') {
          sendActiveTab(item.value);
          navigate('/cms-quick-start');
        }
      } else if (item.value === 4) {
        setToSemeCookie('activeTab', '4');
        if (process.env.NODE_ENV === 'development') {
          sendActiveTab(item.value);
          navigate('/reportEmbed');
        }
      } else {
        setToSemeCookie('activeTab', '2');
        if (process.env.NODE_ENV === 'development') {
          sendActiveTab(item.value);
          navigate('/setting-quick-start');
          //window.location.href = `http://setting.zys.com:3000/authloading?cliantId=${item.appId}`
        }
      }

      if (process.env.NODE_ENV === "production") {  // 本地开发在当前域名跳转，线上在不同域名跳转
        // 为了处理退出登录的问题，在跳转到其他应用的时候，需要先拿到当前用户的token，再跳转到其他域名，保持用户统一。
        try {
          const token = await getTokenByTicktAndCliant(getTickte(), item.appId)
          const hrefUrl = `${item.url}?cliantId=${item.appId}&token=${token.data.result.accessToken.replace(/\s/g, "_")}&rc_access_ticket=${getTickte()}`;
          if (item.blank) {
            window.open(hrefUrl)
          } else {
            window.location.href = hrefUrl
          }
        } catch (error) {
          window.location.href = `${process.env.REACT_APP_AUTHPATH}/oauth/authorized?clientId=${getcliantId()}&loginUrl=${process.env.REACT_APP_AUTHLOGIN}&state=zero`
        }
      }
    } else {
      if (!item.active) {
        setToSemeCookie('rc_sc_login_module', item.desc)
      }
      if (item.blank) {
        window.open(item.url)
      } else {
        window.location.href = item.url
      }
    }

  }

  const getCenterName = () => {
    return getDataFormSeamCookie('rc_sc_login_module')
  }

  return (
    <header style={{ height: "51px", verticalAlign: 'center', zIndex: "99" }} className="top-header flex bg-white w-full items-center fixed overflow-hidden boxShodow">
      {
        <div className="flex flex-row h-full m-auto mr-0 ml-5 flex-none w-28 cursor-pointer">
          {/* <img src={getDataFormSeamCookie('rc_sc_login_brand_logo') || logo} alt="" className="w-28 h-4/5 m-auto cursor-pointer" onClick={() => { */}
          <img src={getDataFormSeamCookie('rc_sc_login_brand_logo') || logo} alt="" className="h-[85px] relative -top-[14px]"
            onClick={() => {
              handleClick(modules[0])
            }}
          />
        </div>
      }
      <div className="fixed left-[10000px] bottom-[-10000px]">
        {/* 预加载图片 */}
        {/* {modules.map(item => <img className="w-[1px]" alt="" src={item.active ? item.activeIcon : item.icon} />)} */}
      </div>
      <div className="grow flex items-center text-base font-semibold ml-4">
        {/* {intl.get('header.' + (getCenterName() || ''))} */}
        {/* {getCenterName()} */}
      </div>
      {/* <div style={{ marginRight: 20 }} className="flex items-center cursor-pointer justify-center menuIcon" >
        <Tooltip placement="bottom" title="Help" overlayClassName="header-title-tip">
          <img src={helpIcon} onClick={() => {
            const moudulesDatas = modules.find((res) => res.url.indexOf(window.location.host) > -1);
            toOtherSysUrl(8, moudulesDatas?.openUrl, true)
            // window.open(moudulesDatas?.openUrl ?? '#', '_blank')
          }} alt="API开发平台" />
        </Tooltip>
      </div> */}
      {/* <div style={{ marginRight: 20 }} className="flex items-center cursor-pointer justify-center menuIcon" >
        <img src={shopHome} onClick={() => {
          try {
            const data = getDataFormSeamCookie('rc_sc_store_config')
            const parseData = JSON.parse(data)
            if (parseData.domain) {
              window.open(parseData.domain, '_blank')
            }
          } catch (error) {
            console.log(error)
          }
        }} alt="shopHome" />
      </div> */}
      {/* <Popover content={ModuleMenuComp(navigate, handleClick)} placement="bottomRight" overlayClassName="moduleMenuPop" >
        <div style={{ marginRight: 20 }} className="flex items-center cursor-pointer justify-center menuIcon" >
          <img src={menuImg} alt="store" />
        </div>
      </Popover> */}
      <div className="h-full flex-none" style={{ lineHeight: "51px", marginRight: '2%' }}>
        <Popover content={MenuComp(navigate, userInfo)} placement="bottomRight" overlayClassName="userMenuPop">
          <span className="h-full flex items-center cursor-pointer">
            {/* <img alt="" width="30" className="inline-block align-middle" src={userIcon} /> */}
            <div className="rounded-full flex justify-center items-center text-lg text-white" style={{ background: '#397DFF', width: '30px', height: '30px' }}>{userInfo?.username?.substr(0, 1)}</div>
            <span className="align-middle ml-2">{userInfo?.username}</span>
          </span>
        </Popover>
      </div>
    </header>
  );
};
export default Header;
