import apis from '@/framework/config/api-config'
import ApiRoot from '@/framework/api/fetcher'
import { getDataFormSeamCookie } from '@/utils/utils'

export const getSubscriptionList = async (param: any) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().getSubscriptionListPage({
    body: param
  })
  console.log('get subscriptionlistpage view data:', res)
  return {
    records: res?.subscriptionFindPage?.records ?? [],
    total: res?.subscriptionFindPage?.total ?? 0
  }
}

export const pauseSubscription = async (id: string) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().pauseSubscription(id)
  console.log('pause subscription view data:', res)
  return res?.subscriptionPause || false
}

export const resumeSubscription = async (id: string) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().resumeSubscription(id)
  console.log('resume subscription view data:', res)
  return res?.subscriptionResume || false
}

export const subscriptionUpdate = async (input: any) => {
  const params = {...input, 
    storeId: getDataFormSeamCookie('rc_sc_login_store_id')
  }
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().subscriptionUpdate(params)
  console.log('resume subscriptionUpdate view data:', res)
  return res?.subscriptionUpdate || false
}

export const getSubscriptionDetail = async (id: string) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().subscriptionDetail({
    id
  })
  console.log("get subscriptiondetail view data:", res)
  return res || {}
}
// export const getSubscriptionFindByConsumerId = async (consumerId: any) => {
//   const res = await ApiRoot().subscriptions().subscriptionFindByConsumerId({
//     consumerId
//   })
//   console.log("get subscriptiondetail view data:", res)
//   return res?.subscriptionFindByConsumerId || []
// }

export const updateSubscriptionAddress = async (id: string, address: any) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().updateSubscriptionAddress(id, address)
  console.log("update subscription address view data:", res)
  return res?.subscriptionUpdateAddress || false
}

export const updateNextDeliveryDate = async (id: string, date: string) => {
  const res = await ApiRoot({ url: apis?.com_subscription }).subscriptions().subscriptionScheduleNextDelivery({ body: { id, nextDeliveryDate: date, operator: 'zz' } })
  console.log("update subscription next delivery date view data:", res)
  return res?.subscriptionScheduleNextDelivery || false
}

export const upsertSubscriptionComment = async (param: any) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().upsertSubscriptionComment({ body: param })
  console.log("upsert subscription comment view data:", res)
  return res?.subscriptionCommentUpsert || false
}

export const subscriptionSettingCreate = async (param: any) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().subscriptionSettingCreate(param)
  console.log("subscriptionSettingCreate comment view data:", res)
  return res?.subscriptionSettingCreate || false
}

export const subscriptionSettingFindPage = async (param: any) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().subscriptionSettingFindPage(param)
  console.log("subscriptionSettingFindPage comment view data:", res)
  return res?.subscriptionSettingFindPage || []
}

export const subscriptionSettingUpdate = async (param: any) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().subscriptionSettingUpdate(param)
  console.log("subscriptionSettingUpdate comment view data:", res)
  return res?.subscriptionSettingUpdate || false
}

export const subscriptionSettingDelete = async (param: any) => {
  const res = await ApiRoot({ url: apis?.fulfillment }).subscriptions().subscriptionSettingDelete(param)
  console.log("subscriptionSettingDelete comment view data:", res)
  return res?.subscriptionSettingDelete || false
}
