import React from 'react'
import Menus from '@/common/components/Menus'
import { menus } from "@/lib/menus";

export const getMenuData = (activeTab: number) => {
  let menuData: any = menus
  switch (activeTab) {
    case 2:
      // @ts-ignore
      menuData = SettingMenus
      break
  }
  return menuData
}
export default function MenusWrapper() {
  return (
    <Menus menus={ menus} />
  )
}
