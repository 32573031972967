/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-01-17 11:05:26
 * @LastEditors: yuszhou yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-03-14 14:47:42
 * @FilePath: \rc_china_storeportal\src\routers\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import React from 'react'
import { lazy } from 'react' // 路由懒加载
import CmsAuthLogin from '../views/cmsAuth'
// import ProductList from '../views/productList'
const Layout = lazy(() => import('@/common/components/Layout'))
const Home = lazy(() => import('../views/index'))
const Login = lazy(() => import('../views/login'))
const LoginStore = lazy(() => import('../views/loginStore'))
const SelectStore = lazy(() => import('../views/selectStore'))
const LoginBrand = lazy(() => import('../views/loginBrand'))
const ProductList = lazy(() => import('../views/productList'))
const AddProduct = lazy(() => import('../views/productDetail'))
const ResetPassword = lazy(() => import('../views/resetPassword'))
const Register = lazy(() => import('../views/register'))
const PetOwnerList = lazy(() => import('@/views/petOwnerList'))
const PetOwnerDetail = lazy(() => import('@/views/petOwnerDetail'))
const OrderList = lazy(() => import('@/views/orderList'))
const ValetOrder = lazy(() => import('@/views/valetOrder'))
const OrderDetail = lazy(() => import('@/views/orderDetail'))
const OrderSetting = lazy(() => import('@/views/orderSetting'))
const BlockList = lazy(() => import('@/views/orderSetting/blockList'))
const OrderEligibleForReturnList = lazy(() => import('@/views/orderEligibleForReturn/list'))
const OrderEligibleForReturnAdd = lazy(() => import('@/views/orderEligibleForReturn/add'))
const ReturnOrderList = lazy(() => import('@/views/returnOrder/list'))
const ReturnOrderDetail = lazy(() => import('@/views/returnOrder/detail'))
const ComingSoon = lazy(() => import('@/views/comingSoon'))
const Dashboard = lazy(() => import('@/views/dashboard'))
const StoreDashboard = lazy(() => import('@/views/storeDashboard'))
const AuthLoading = lazy(() => import('@/views/authLoading'))
const BatchExport = lazy(() => import('@/views/batchExport'))
const ExportReport = lazy(() => import('@/views/batchExportReport'))
const OrderFlowReturn = lazy(() => import('@/views/orderFlow/orderFlow'))
const OrderFlowList = lazy(() => import('@/views/orderFlow/orderFlowlist'))
const OrderFlowSales = lazy(() => import('@/views/orderFlow/orderflowSales'))
const GiftCardManagement = lazy(() => import('@/views/giftCardManagement'))

export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  breadcrumbName?: string
  Navigate?: string
  key?: string
}

let routes: any[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: 'test', breadcrumbName: 'test', element: <StoreDashboard /> },
      { path: 'home', element: <Home /> },
      { path: 'batch-export/:from', element: <BatchExport />, key: 'batch_export', breadcrumbName: 'Batch Export' },
      { path: 'export-report', element: <ExportReport />, key: 'export_report', breadcrumbName: 'menu.exports' },
      {
        path: 'shipment',
        breadcrumbName: 'Shipment',
        children: [
          { index: true, element: <OrderList /> },
          { path: 'shipment-list', element: <OrderList />, breadcrumbName: 'My Shipment' },
        ],
      },
      {
        path: 'product',
        breadcrumbName: 'Product',
        children: [
          { index: true, element: <ProductList /> },
          {
            path: 'product-list',
            breadcrumbName: 'Product List',
            children: [
              { index: true, element: <ProductList /> },
              { path: 'product-detail', element: <AddProduct />, breadcrumbName: 'Product Detail' },
              { path: 'product-add', element: <AddProduct />, breadcrumbName: 'Add Product' },
            ],
          },
          {
            path: 'gift-card-management',
            breadcrumbName: 'Gift Card Management',
            children: [
              { index: true, element: <GiftCardManagement /> },
            ],
          },
        ],
      },
      {
        path: 'consumer',
        breadcrumbName: 'Consumer',
        children: [
          { index: true, element: <PetOwnerList /> },
          {
            path: 'consumer-list', breadcrumbName: 'menu.my_pet_owner'
            , children: [
              {
                index: true,
                element: <PetOwnerList />,
              },
              { path: 'consumer-detail', element: <PetOwnerDetail />, breadcrumbName: 'Consumer Detail' },
            ]
          },
        ],
      },

      {
        path: 'order',
        breadcrumbName: 'Order',
        children: [
          { index: true, element: <OrderList /> },
          {
            path: 'order-list',
            breadcrumbName: 'My Orders',
            children: [
              { index: true, element: <OrderList /> },
              { path: 'order-detail', element: <OrderDetail />, breadcrumbName: 'Order Detail' },
            ],
          }, {
            path: 'valet-order',
            breadcrumbName: 'Valet Order',
            children: [
              { index: true, element: <ValetOrder /> },
              // { path: 'order-detail', element: <OrderDetail />, breadcrumbName: 'Order Detail' },
            ],
          },
          {
            path: 'order-setting',
            breadcrumbName: 'Order Setting',
            children: [
              { index: true, element: <OrderSetting /> },
              { path: 'blocklist', element: <BlockList />, breadcrumbName: 'blockList' },
              {
                path: 'order-flow',
                breadcrumbName: 'menu.order_flow',
                children: [
                  { index: true, element: <OrderFlowList /> },
                  {
                    path: 'sales-order',
                    children: [{ index: true, element: <OrderFlowSales /> }],
                    breadcrumbName: 'sales-order',
                  },
                  {
                    path: 'return-order',
                    children: [{ element: <OrderFlowReturn />, index: true }],
                    breadcrumbName: 'return-order',
                  },
                ],
              },
            ],
          },
          {
            path: 'order-return-list',
            breadcrumbName: 'menu.Return Order',
            children: [
              { index: true, element: <ReturnOrderList /> },
              { path: 'order-return-detail', element: <ReturnOrderDetail />, breadcrumbName: 'Detail' },
              {
                path: 'apply',
                breadcrumbName: 'order.Return Order Application',
                children: [
                  { index: true, element: <OrderEligibleForReturnList /> },
                  { path: 'add-order', element: <OrderEligibleForReturnAdd />, breadcrumbName: 'Add' },
                ],
              },
            ],
          },
        ],
      },
    ],
    key: 'store',
  },
  { path: '/authloading', element: <AuthLoading />, key: 'store' },
  { path: '/login', element: <Login />, key: 'store' },
  { path: '/store/select', element: <SelectStore />, key: 'store' },
  { path: '/login/store', element: <LoginStore />, key: 'store' },
  { path: '/login/brand', element: <LoginBrand />, key: 'store' },
  { path: '/resetPassword', element: <ResetPassword />, key: 'store' },
  { path: '/register', element: <Register />, key: 'store' },
  { path: '/cms_login', element: <CmsAuthLogin />, key: 'store' },
  { path: '*', element: <Home />, key: 'store' },
]

// The useRoutes() hook allows you to define your routes as JavaScript objects
// instead of <Routes> and <Route> elements. This is really just a style
// preference for those who prefer to not use JSX for their routes config.

export default routes
